import moment from "moment";

export class Clock {
  constructor({ animate, preview, update, animateOff, triggerAnimation }) {
    this.onAir = false;
    this.time = 0;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.triggerAnimation = triggerAnimation;
    this.name = "CLOCK";
    this.scene = "Clock";
    this.data = [];
    this.home_score = 0;
    this.away_score = 0;
    this.period_number = 0;
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [
      { name: "Home Team Name", value: { text: options.game.home_team.code } },
      { name: "Away Team Name", value: { text: options.game.away_team.code } },
      { name: "Game Clock", value: { text: options.clock } },
    ];
    this.sendPreview(this.scene, 25, this.data, "IN");
    this.time++;
  }
  animate({ options }) {
    this.onAir = true;
    this.added_time_on_air = false;
    this.data = [];
    this.home_score = options.game.home_scorers?.length || 0;
    this.away_score = options.game.away_scorers?.length || 0;

    this.data.push({
      name: "Competition",
      value: { text: options.game?.competition?.name || "" },
    });
    this.data.push({
      name: "Competition Colour",
      value: { colour: options.game?.competition?.colour || "" },
    });
    this.data.push({
      name: "Home Team Kit BG Colour",
      value: {
        colour:
          options.game.home_team?.variables?.[
            options.game?.home_team_kit + "_kit_bg"
          ] || "",
      },
    });
    this.data.push({
      name: "Home Team Kit Text Colour",
      value: {
        colour:
          options.game.home_team?.variables?.[
            options.game?.home_team_kit + "_kit_text"
          ] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit BG Colour",
      value: {
        colour:
          options.game.away_team?.variables?.[
            options.game?.away_team_kit + "_kit_bg"
          ] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit Text Colour",
      value: {
        colour:
          options.game.away_team?.variables?.[
            options.game?.away_team_kit + "_kit_text"
          ] || "",
      },
    });
    this.data.push({
      name: "Home Team Code",
      value: { text: options.game.home_team.code || "" },
    });
    this.data.push({
      name: "Away Team Code",
      value: { text: options.game.away_team.code || "" },
    });
    this.data.push({
      name: "Home Score",
      value: { text: this.home_score },
    });
    this.data.push({
      name: "Away Score",
      value: { text: this.away_score },
    });
    this.data.push({
      name: "Leg",
      value: { text: options.game?.leg },
    });
    this.data.push({
      name: "Home Agg Score",
      value: {
        text: options.game.agg_home_score + this.home_score,
      },
    });
    this.data.push({
      name: "Away Agg Score",
      value: {
        text: options.game.agg_away_score + this.away_score,
      },
    });
    this.data.push({
      name: "Home Team Badge",
      value: {
        image: "${user}" + options.game?.home_team?.variables?.badge,
      },
    });
    this.data.push({
      name: "Home Team Secondary Badge",
      value: {
        image: "${user}" + options.game?.home_team?.variables?.alt_badge || "",
      },
    });

    this.data.push({
      name: "Away Team Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Away Team Secondary Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.alt_badge || "",
      },
    });

    let clock = options.period.kick_off;
    this.kick_off = options.period.kick_off;
    this.period_number = options.period.number;
    this.data.push({
      name: "Additional Time",
      value: {
        text: options.added_time || "",
      },
    });
    this.data.push({
      name: "Period",
      value: { text: options.period.number },
    });

    if (options.period.number === 2) {
      clock -= 1000 * 60 * (options?.game?.config?.half_duration || 45);
    }
    if (options.period.number === 3) {
      clock -= 1000 * 60 * ((options?.game?.config?.half_duration || 45) * 2);
    }
    if (options.period.number === 4) {
      clock -= 1000 * 60 * 105;
    }

    if (options?.game?.config?.clock_direction === "down") {
      if (options.period?.number === 4) {
        this.data.push({
          name: "Game Clock",
          value: {
            action: "start",
            time:
              60 * options?.game?.config?.et_duration -
              (moment().valueOf() - options.period.kick_off) / 1000,
          },
        });
      } else {
        this.data.push({
          name: "Game Clock",
          value: {
            action: "start",
            time:
              60 * options?.game?.config?.half_duration -
              (moment().valueOf() - options.period.kick_off) / 1000,
          },
        });
      }
    } else {
      this.data.push({
        name: "Game Clock",
        value: {
          action: "start",
          time: moment().valueOf() - clock,
        },
      });
    }

    let home_reds = options.game?.lineup?.home?.reduce((prev, curr) => {
      return (prev += curr.red_card || curr.dbl_yellow_card ? 1 : 0);
    }, 0);

    let away_reds = options.game?.lineup?.away?.reduce((prev, curr) => {
      return (prev += curr.red_card || curr.dbl_yellow_card ? 1 : 0);
    }, 0);

    if (
      !options.game?.lineup?.away ||
      options.game?.lineup?.away?.length === 0 ||
      window.ENV?.REACT_APP_IMG ||
      process.env.REACT_APP_IMG
    ) {
      try {
        const home_reds_local = JSON.parse(
          localStorage.getItem(options.game?._id + "home_reds")
        )?.find((r) => r.game === options?.game?._id);
        home_reds = 0;
        if (home_reds_local?.one) {
          home_reds += 1;
        }
        if (home_reds_local?.two) {
          home_reds += 1;
        }
        if (home_reds_local?.three) {
          home_reds += 1;
        }
      } catch (err) {}
    }

    if (
      !options.game?.lineup?.away ||
      options.game?.lineup?.away?.length === 0 ||
      window.ENV?.REACT_APP_IMG ||
      process.env.REACT_APP_IMG
    ) {
      try {
        const away_reds_local = JSON.parse(
          localStorage.getItem(options.game?._id + "away_reds")
        )?.find((r) => r.game === options?.game?._id);
        away_reds = 0;
        if (away_reds_local?.one) {
          away_reds += 1;
        }
        if (away_reds_local?.two) {
          away_reds += 1;
        }
        if (away_reds_local?.three) {
          away_reds += 1;
        }
      } catch (err) {}
    }

    this.home_reds_total = home_reds;
    this.away_reds_total = away_reds;

    this.data.push({
      name: "Home Red Cards",
      value: {
        text: home_reds || 0,
      },
    });
    this.data.push({
      name: "Away Red Cards",
      value: {
        text: away_reds || 0,
      },
    });

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.added_time_on_air = false;
    this.sendAnimateOff(this.scene, []);
  }
  update({ options }) {
    this.data = [];
    if (options.type === "ADDED_TIME") {
      this.data.push({
        name: "Additional Time",
        value: {
          text: options.added_time,
        },
      });
      this.sendUpdate(this.scene, this.data);
    } else {
      let home_reds = options.game?.lineup?.home?.reduce((prev, curr) => {
        return (prev += curr.red_card || curr.dbl_yellow_card ? 1 : 0);
      }, 0);

      let away_reds = options.game?.lineup?.away?.reduce((prev, curr) => {
        return (prev += curr.red_card || curr.dbl_yellow_card ? 1 : 0);
      }, 0);

      if (
        !options.game?.lineup?.away ||
        options.game?.lineup?.away?.length === 0 ||
        window.ENV?.REACT_APP_IMG ||
        process.env.REACT_APP_IMG
      ) {
        try {
          const home_reds_local = JSON.parse(
            localStorage.getItem(options.game?._id + "home_reds")
          )?.find((r) => r.game === options?.game?._id);
          home_reds = 0;
          if (home_reds_local?.one) {
            home_reds += 1;
          }
          if (home_reds_local?.two) {
            home_reds += 1;
          }
          if (home_reds_local?.three) {
            home_reds += 1;
          }
        } catch (err) {}
      }

      if (
        !options.game?.lineup?.away ||
        options.game?.lineup?.away?.length === 0 ||
        window.ENV?.REACT_APP_IMG ||
        process.env.REACT_APP_IMG
      ) {
        try {
          const away_reds_local = JSON.parse(
            localStorage.getItem(options.game?._id + "away_reds")
          )?.find((r) => r.game === options?.game?._id);
          away_reds = 0;
          if (away_reds_local?.one) {
            away_reds += 1;
          }
          if (away_reds_local?.two) {
            away_reds += 1;
          }
          if (away_reds_local?.three) {
            away_reds += 1;
          }
        } catch (err) {}
      }

      if (
        this.home_score !== (options.game.home_scorers?.length || 0) ||
        this.away_score !== (options.game.away_scorers?.length || 0) ||
        this.kick_off !== options.period.kick_off ||
        this.period_number !== options.period.number ||
        this.home_reds_total !== home_reds ||
        this.away_reds_total !== away_reds
      ) {
        this.data.push({
          name: "Home Red Cards",
          value: {
            text: home_reds || 0,
          },
        });
        this.data.push({
          name: "Away Red Cards",
          value: {
            text: away_reds || 0,
          },
        });
        this.data.push({
          name: "Home Score",
          value: { text: options.game.home_scorers?.length || 0 },
        });
        this.data.push({
          name: "Away Score",
          value: { text: options.game.away_scorers?.length || 0 },
        });
        this.data.push({
          name: "Home Agg Score",
          value: {
            text:
              options.game.agg_home_score +
              (options.game.home_scorers?.length || 0),
          },
        });
        this.data.push({
          name: "Away Agg Score",
          value: {
            text:
              options.game.agg_away_score +
              (options.game.away_scorers?.length || 0),
          },
        });
        let clock = options.period.kick_off;

        if (options.period.number === 2 && !options.period.kick_off) {
          clock = 1000 * 60 * (options?.game?.config?.half_duration || 45);
        } else if (options.period.number === 2) {
          clock -= 1000 * 60 * (options?.game?.config?.half_duration || 45);
        }
        if (options.period.number === 3 && !options.period.kick_off) {
          clock =
            1000 * 60 * ((options?.game?.config?.half_duration || 45) * 2);
        } else if (options.period.number === 3) {
          clock -=
            1000 * 60 * ((options?.game?.config?.half_duration || 45) * 2);
        }
        if (options.period.number === 4 && !options.period.kick_off) {
          clock = 1000 * 60 * 105;
        } else if (options.period.number === 4) {
          clock -= 1000 * 60 * 105;
        }

        if (!options.period.kick_off) {
          this.data.push({
            name: "Game Clock",
            value: {
              action: "reset",
              time: clock,
            },
          });
        } else {
          if (options?.game?.config?.clock_direction === "down") {
            if (options.period?.number === 4) {
              this.data.push({
                name: "Game Clock",
                value: {
                  action: "start",
                  time:
                    60 * options?.game?.config?.et_duration -
                    (moment().valueOf() - options.period.kick_off) / 1000,
                },
              });
            } else {
              this.data.push({
                name: "Game Clock",
                value: {
                  action: "start",
                  time:
                    60 * options?.game?.config?.half_duration -
                    (moment().valueOf() - options.period.kick_off) / 1000,
                },
              });
            }
          } else {
            this.data.push({
              name: "Game Clock",
              value: {
                action: options.period.kick_off ? "start" : "stop",
                time: moment().valueOf() - clock,
              },
            });
          }
        }

        this.data.push({
          name: "Period",
          value: { text: options.period.number },
        });
        this.sendUpdate(this.scene, this.data);
      }
      this.home_reds_total = home_reds;
      this.away_reds_total = away_reds;
      this.home_score = options.game.home_scorers?.length || 0;
      this.away_score = options.game.away_scorers?.length || 0;
      this.period_number = options.period.number;
      this.kick_off = options.period.kick_off;
    }
  }
}
